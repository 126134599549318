export default {
  studentCourser: [],
  studentClasses: [],
  enrolled_courses: {
    results: [],
  },
  subjects_of_contact: [],
  teachers_groups: [],
  student_course_stats: [],
}
