export default {
  foldersList: [],
  courses_list: {
    results: [],
  },
  courses_list_sync: {
    results: [],
  },
  single_lesson_history: {
    contents: [],
  },
  one_folder: [],
  one_course: {},
  chapters_list: {},
  chapters: [],
  one_chapter: {
    lessons: [],
  },
  classCourse: [],
  contents: [],
  oneContent: {},
  lessons: {},
  lesson: {
    contents: [
      {
        lesson: null,
      },
    ],
    lesson_relation: [],
  },
  question_base: [],
  oneRelation: {},
  lesson_history: {
    lesson_history: {
      results: [],
    },
  },
  one_lesson_history: {
    lesson_history: [],
  },
  check_results: [],
  notifications: {
    results: [],
  },
  students_in_course: {
    results: [],
  },
  students_in_class: {
    results: [],
  },
  classInCourse: [],
  form_types: [],
}
