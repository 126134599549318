export default {
  GET_STUDENT_COURSE: state => state.studentCourser,
  GET_STUDENT_CLASSES: state => state.studentClasses,
  GET_COURSES_BY_CONTACT(state) {
    return state.enrolled_courses
  },
  GET_SUBJECTS_OF_CONTACT(state) {
    return state.subjects_of_contact
  },
  GET_TEACHER_GROUPS(state) {
    return state.teachers_groups
  },
  GET_STUDENT_COURSE_STATS(state) {
    return id => state.student_course_stats.find(el => el.course === id) || {}
  },
}
