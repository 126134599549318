import {
  getOneFolder,
  getFolders,
  createFolders,
  deleteFolders,
  editFolder,
  getOneFile,
  getRelationSystem,
} from '@/api/content'

export default {
  FETCH_FOLDERS_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      getFolders()
        .then(response => {
          commit('SET_FOLDERS_LIST', response.data)
          commit('SET_MAIN_WINDOW', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_ONE_FOLDER({ commit }, id) {
    return new Promise((resolve, reject) => {
      getOneFolder(id)
        .then(response => {
          commit('SET_MAIN_WINDOW', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  UPDATE_FOLDER(_, data) {
    return new Promise((resolve, reject) => {
      editFolder(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  DELETE_FOLDERS(_, id) {
    return new Promise((resolve, reject) => {
      deleteFolders(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  CREATE_FOLDERS(_, data) {
    return new Promise((resolve, reject) => {
      createFolders(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_ONE_CONTENT_FILE(_, data) {
    return getOneFile(data)
  },
  FETCH_RELATION_SYSTEM({ commit }, data) {
    return new Promise((resolve, reject) => {
      getRelationSystem(data)
        .then(response => {
          commit('SET_RELATION_SYSTEM', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
