import {

  getComments,
  createComment,
  updateComment,
  deleteComment,
  getOneComment,
} from '@/api/files'

export default {

  // comments
  FETCH_COMMENTS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getComments(params)
        .then(response => {
          commit('SET_COMMENTS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // get one comment
  FETCH_ONE_COMMENT(_, id) {
    return new Promise((resolve, reject) => {
      getOneComment(id)
        .then(response => {
          // commit('SET_ONE_COMMENT', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  CREATE_COMMENT(_, data) {
    return createComment(data)
  },

  UPDATE_COMMENT(_, data) {
    return updateComment(data)
  },

  DELETE_COMMENT(_, id) {
    return deleteComment(id)
  },
}
