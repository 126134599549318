export default {
  SET_STUDENT_COURSE(state, data) {
    state.studentCourser = data
  },
  SET_STUDENT_CLASSES(state, data) {
    state.studentClasses = data
  },
  SET_COURSES_BY_CONTACT(state, data) {
    state.enrolled_courses = data
  },
  SET_COURSES_BY_CONTACT_LAZY(state, { results, ...data }) {
    state.enrolled_courses = {
      ...data,
      results: [...results, ...state.enrolled_courses.results],
    }
  },
  SET_SUBJECTS_OF_CONTACT(state, data) {
    state.subjects_of_contact = data
  },
  SET_TEACHER_GROUPS(state, data) {
    state.teachers_groups = data
  },
  SET_STUDENT_COURSE_STATS(state, data) {
    state.student_course_stats = data
  },
}
