import http from '@axios'

export function getQuestionBase(params) {
  return http({
    method: 'GET',
    url: '/questions/question_base/text_explorer/',
    params,
  })
}

export function copyTest(data) {
  console.log('data ', data)
  return http({
    method: 'POST',
    url: '/questions/question_base/duplicate/',
    data,
  })
}

export function getOneQuestionFolder(id) {
  return http({
    method: 'GET',
    url: `questions/question_base/${id}/`,
  })
}

export function createQuestionFolder(data) {
  return http({
    method: 'POST',
    url: 'questions/question_base/',
    data,
  })
}

export function updateQuestionFolder(data) {
  return http({
    method: 'PATCH',
    url: `questions/question_base/${data.id}/`,
    data,
  })
}

export function createQuestion(data) {
  return http({
    method: 'POST',
    url: 'questions/question/',
    data,
  })
}

export function getOneQuestion(id) {
  return http({
    method: 'GET',
    url: `/questions/question/${id}/`,
  })
}

export function updateOneQuestion(data) {
  return http({
    method: 'PATCH',
    url: `/questions/question/${data.id}/`,
    data,
  })
}

export function deleteQuestion(id) {
  return http({
    method: 'DELETE',
    url: `/questions/question/${id}/`,
  })
}

export function deleteQuestionFolder(id) {
  return http({
    method: 'DELETE',
    url: `/questions/question_base/${id}/`,
  })
}

export function getQuestionsInfo(id) {
  return http({
    method: 'GET',
    url: `/questions/question/${id}/view_detail_info/`,
  })
}
