import http from '@/libs/axios'

export function getTransactions(params) {
  return http.get('/finance/transaction/', { params })
}

export function createTransactions(data) {
  return http.post('/finance/transaction/', data)
}

export function updateTransactions({ id, ...rest }) {
  return http.patch(`/finance/transaction/${id}/`, { ...rest })
}

export function deleteTransactions(id) {
  return http.delete(`/finance/transaction/${id}/`)
}

export function getIncomeStatistics(params) {
  return http.get('/finance/transaction/income_statistic/', { params })
}

export function getUserTransaction() {
  return http.get('/finance/transaction_history/')
}

export function getUserTransactionHistory(params) {
  return http.get('/finance/debit/', { params })
}
