import http from '@/libs/axios'

export function getCoursesList(params) {
  return http.get('/courses/course/', { params })
}

export function deleteCourse(id) {
  return http.delete(`/courses/course/${id}/`)
}

export function createCoursesList(data) {
  return http.post('/courses/course/', data)
}

export function updateCoursesList(data) {
  return http.patch(`/courses/course/${data.id}/`, data)
}

export function getStudentCourses(id) {
  return http({
    method: 'GET',
    url: `course/${id}/courses`,
  })
}

export function getOneCourse(params) {
  if (typeof params === 'object') {
    return http({
      method: 'GET',
      url: `/courses/course/${params.id}/`,
      params,
    })
  }
  return http({
    method: 'GET',
    url: `/courses/course/${params}/`,
  })
}

export function CreateChapter(data) {
  return http({
    method: 'POST',
    url: '/courses/chapter/',
    data,
  })
}

export function getChapterList(params) {
  return http({
    method: 'GET',
    url: '/courses/chapter/',
    params,
  })
}

export function getOneChapter(id) {
  return http({
    method: 'GET',
    url: `/courses/chapter/${id}/`,
  })
}

export function UpdateChapter(data) {
  return http({
    method: 'PATCH',
    url: `/courses/chapter/${data.id}/`,
    data,
  })
}

export function DeleteChapter(id) {
  return http({
    method: 'DELETE',
    url: `/courses/chapter/${id}/`,
  })
}

export function createClassCourse(data) {
  return http.post('/courses/course_relation/', data)
}

export function getClassCourse(params) {
  return http.get('/courses/course_relation/', { params })
}

export function updateClassCourse(data) {
  return http.patch(`/courses/course_relation/${data.id}/`, data)
}

export function deleteClassCourse(id) {
  return http.delete(`/courses/course_relation/${id}/`)
}

export function getOneRelation(id) {
  return http.get(`/courses/course_relation/${id}/`)
}

export function getLesson(params) {
  return http({
    method: 'GET',
    url: '/courses/lesson/',
    params,
  })
}

export function getOneLesson(id) {
  return http({
    method: 'GET',
    url: `/courses/lesson/${id}/`,
  })
}

export function createLesson(data) {
  return http({
    method: 'POST',
    url: '/courses/lesson/',
    data,
  })
}

export function updateLesson(data) {
  return http({
    method: 'PATCH',
    url: `/courses/lesson/${data.id}/`,
    data,
  })
}

export function deleteLesson(id) {
  return http({
    method: 'DELETE',
    url: `/courses/lesson/${id}/`,
  })
}

export function getlessonContent(params) {
  return http({
    method: 'GET',
    url: '/courses/content/',
    params,
  })
}

export function getOnelessonContent(id) {
  return http({
    method: 'GET',
    url: `/courses/lesson_relation/${id}/`,
  })
}

export function deleteOnelessonContent(id) {
  return http({
    method: 'DELETE',
    url: `/courses/lesson_relation/${id}/`,
  })
}

export function createLessonContent(data) {
  return http({
    method: 'POST',
    url: '/courses/lesson_relation/',
    data,
  })
}

export function updateLessonContent(data) {
  return http({
    method: 'PATCH',
    url: `/courses/lesson_relation/${data.id}/`,
    data,
  })
}

export function copyCourse(id) {
  return http({
    method: 'GET',
    url: `/courses/course/${id}/copy_course/`,
  })
}

export function createLessonViewHistory(data) {
  return http({
    method: 'POST',
    url: '/courses/lesson_history/',
    data,
  })
}

export function getLessonViewHistory(params) {
  return http({
    method: 'GET',
    url: '/courses/lesson_history/',
    params,
  })
}

export function getOneLessonViewHistory(id) {
  return http({
    method: 'GET',
    url: `/courses/lesson/${id}/history/`,
  })
}

export function getOneLessonViewHistoryForAdmin({ id, student_id }) {
  return http({
    method: 'GET',
    url: `/courses/lesson/${id}/history/`,
    params: { student_id },
  })
}

export function updateLessonViewHistory(data) {
  return http({
    method: 'PATCH',
    url: `/courses/lesson_history/${data.id}/`,
    data,
  })
}

export function getOneLessonHistory(id) {
  return http({
    method: 'GET',
    url: `/courses/lesson_history/${id}/`,
  })
}

export function checkTests(id) {
  return http({
    method: 'GET',
    url: `/courses/lesson_history/${id}/check/`,
  })
}
export function getOneCourseForAdmin({ id, student_id }) {
  return http({
    method: 'GET',
    url: `/courses/course/${id}/`,
    params: { student_id },
  })
}
export function getNotificationsList(params) {
  return http({
    method: 'GET',
    url: '/chat/notifications/',
    params,
  })
}
export function importCourseOrLesson(data) {
  return http({
    method: 'POST',
    url: '/courses/import/',
    data,
  })
}
export function getStudentsInCourse({ id, ...params }) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/students_individual/`,
    params: { ...params },
  })
}
export function getStudentsByTeacher({ id, ...params }) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/students/`,
    params: { ...params },
  })
}
export function getStudentsInClass({ id, ...params }) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/students_group/`,
    params: { ...params },
  })
}
// export function sendTestLessonAnswers(data) {
//   return http({
//     method: 'POST',
//     url: '/courses/lesson_answers',
//   })
// }

export function getCoursesInClass(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/is_not_individual/`,
  })
}

export function updateCourseRelation(data) {
  return http({
    method: 'POST',
    url: '/courses/lesson_relation/update_lesson_relation/',
    data,
  })
}

export function updateLessonDraft(data) {
  return http({
    method: 'POST',
    url: '/courses/update_lesson_draft/',
    data,
  })
}

export function exportStudentsResult(data) {
  return http({
    method: 'POST',
    url: '/courses/lesson/export_excel/',
    responseType: 'blob',
    data,
  })
}
export function getFormTypes(params) {
  return http({
    method: 'GET',
    url: '/courses/form-type/',
    params,
  })
}

export function createFormType(data) {
  return http({
    method: 'POST',
    url: '/courses/form-type/',
    data,
  })
}

export function updateFormType(data) {
  return http({
    method: 'PATCH',
    url: `/courses/form-type/${data.id}/`,
    data,
  })
}

export function deleteFormType(id) {
  return http({
    method: 'DELETE',
    url: `/courses/form-type/${id}/`,
  })
}

export function changeFormTypePosition(data) {
  return http.patch(`/courses/course/${data.id}/`, data)
}
