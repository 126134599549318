import Vue from 'vue'
import axios from 'axios'
import configs from '../../axios.config'

// axios

export const backendPath = configs.baseURL

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${configs.baseURL}/api/v1`,
  timeout: 30000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
