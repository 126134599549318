import {
  getTransactions,
  createTransactions,
  updateTransactions,
  deleteTransactions,
  getIncomeStatistics,
  getUserTransaction,
  getUserTransactionHistory,
} from '@/api/finance'

import { getUsersList } from '@/api/users'

export default {
  FETCH_TRANSACTIONS({ commit }, params) {
    return getTransactions(params)
      .then(res => {
        commit('SET_TRANSACTIONS', res.data)
      })
  },
  CREATE_TRANSACTIONS(_, data) {
    return createTransactions(data)
  },
  UPDATE_TRANSACTIONS(_, data) {
    return updateTransactions(data)
  },
  DELETE_TRANSACTIONS(_, id) {
    return deleteTransactions(id)
  },
  FETCH_CLIENTS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getUsersList(params)
        .then(response => {
          if (!params.search) {
            commit('SET_CLIENTS_LIST', response.data)
          }
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_CLIENTS_LIST_FOR_FILTER({ commit }, params) {
    return new Promise((resolve, reject) => {
      getUsersList(params)
        .then(response => {
          if (!params.search) {
            commit('SET_CLIENTS_LIST_FOR_FILTER', response.data)
          }
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_INCOME_STATISTICS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getIncomeStatistics(params)
        .then(response => {
          commit('SET_INCOME_STATISTICS_LIST', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_USER_TRANSACTION({ commit }) {
    return new Promise((resolve, reject) => {
      getUserTransaction()
        .then(response => {
          commit('SET_USER_TRANSACTION', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_USER_TRANSACTION_HISTORY({ commit }, params) {
    return new Promise((resolve, reject) => {
      getUserTransactionHistory(params)
        .then(response => {
          commit('SET_USER_TRANSACTION_HISTORY', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
