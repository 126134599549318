export default {
  // baseURL: 'http://192.168.31.74:8001',
  // baseURL: 'http://192.168.31.12:8000',
  // baseURL: 'http://192.168.31.19:8003',
  // baseURL: 'http://192.168.31.66:8003',
  // baseURL: 'http://192.168.31.35:8081', // <- HAMZA
  // baseURL: 'http://192.168.31.36:8000', // <- SANJAR
  // baseURL: 'http://192.168.31.89:8000', // <- BEHZOD
  // baseURL: 'http://192.168.31.70:8002', // <- IKROMJON
  // baseURL: 'http://192.168.31.35:8001',
  // baseURL: 'http://192.168.31.111:8002',
  // baseURL: 'http://dicore.uz:1007',
  // baseURL: 'https://185.217.131.131:82',
  // webSocket: 'ws://192.168.31.19:8003/chat',
  // notificationSocket: 'ws://192.168.31.19:8003/notify',
  // webSocket: 'ws://dicore.uz:1007/chat',
  // notificationSocket: 'ws://dicore.uz:1007/notify',
  webSocket: 'ws://192.168.31.35:8081/chat',
  notificationSocket: 'ws://192.168.31.35:8081/notify',
  baseURL: 'https://5-skills.uz:8443',
  // webSocket: 'wss://5-skills.uz:8443/chat',
  // notificationSocket: 'wss://5-skills.uz:8443/notify',
  token_prefix: '5skills',
}
