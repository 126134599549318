export default [
  {
    path: '/course/content-base',
    name: 'content-base',
    component: () => import('@/views/modules/Content/ContentBase.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.content_base',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'head_manager'],
    },
  },
  {
    path: '/course/form-types-base',
    name: 'form-types-base',
    component: () => import('@/views/modules/Courses/FormTypes/FormTypes.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.form_types',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'head_manager'],
    },
  },
  {
    path: '/course/course-administrations',
    name: 'course-administrations',
    component: () => import('@/views/modules/Courses/CourseAdministration.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'admin', 'head_manager'],
    },
  },
  {
    path: '/course/course-administrations/theme-list/:id',
    name: 'theme-list',
    component: () => import('@/views/modules/Courses/ThemeList/ListTheme.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'admin', 'head_manager'],
    },
  },
  {
    path: '/course/course-administrations/theme-list/:id/form-types',
    name: 'theme-list',
    component: () => import('@/views/modules/Courses/ThemeList/FormTypesOrder.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'admin', 'head_manager'],
    },
  },
  {
    path: '/course/course-administrations/theme-list/:id/:themeId',
    name: 'theme-list',
    component: () => import('@/views/modules/Courses/AddContent/AddContent.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
        {
          text: 'others.contents',
          active: true,
        },
        {
          text: 'courses.lesson_title',
          active: true,
        },
        {
          text: 'others.test_attachment',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'admin', 'head_manager'],
    },
  },
  {
    path: '/lessons/:id/test-attachment',
    name: 'lesson-test-attachments',
    component: () => import('@/views/modules/Courses/AddTest/attachmentPage.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'users.course_title',
          active: false,
          to: '',
        },
        {
          text: 'courses.chapter_title',
          active: true,
        },
        {
          text: 'courses.lesson_title',
          active: true,
        },
        {
          text: 'others.test_attachment',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'admin', 'head_manager'],
    },
  },
  {
    path: '/lessons/:id/view',
    name: 'lesson-view',
    component: () => import('@/views/modules/Courses/UserSide/testLessonView.vue'),
    meta: {
      layout: 'full',
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'users.course_title',
          active: false,
          to: '',
        },
        {
          text: 'courses.chapter_title',
          active: true,
        },
        {
          text: 'courses.lesson_title',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'admin', 'head_manager', 'teacher', 'student', 'supervisor'],
    },
  },
  {
    path: '/test-report/:id',
    name: 'test-report-view',
    component: () => import('@/views/modules/Courses/UserSide/testLessonReport.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.course_administration',
          active: false,
          to: '/course/course-administrations',
        },
        {
          text: 'users.course_title',
          active: false,
          to: '',
        },
        {
          text: 'courses.chapter_title',
          active: true,
        },
        {
          text: 'courses.lesson_title',
          active: true,
        },
        {
          text: 'courses.lesson_report',
          active: true,
        },
      ],
      action: 'read',
      resource: ['content_manager', 'admin', 'head_manager', 'teacher', 'student', 'supervisor'],
    },
  },
]
