import {
  updateBranch,
  deleteBranch,
  createBranch,
  getModifiedBranch,
  getRejectBranch,
  getApprovedBranch,
  getBranch,
  createBranchGroup,
  getBranchGroup,
  updateBranchGroup,
  deleteBranchGroup,
  getOneBranchGroup,
  getBranchForGroup,
} from '@/api/branches'

export default {
  FETCH_APPROVED_BRANCH_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getApprovedBranch(params)
        .then(response => {
          commit('SET_APPROVED_BRANCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_REJECT_BRANCH_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getRejectBranch(params)
        .then(response => {
          commit('SET_REJECT_BRANCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_MODIFIED_BRANCH_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getModifiedBranch(params)
        .then(response => {
          commit('SET_MODIFIED_BRANCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  CREATE_BRANCH(_, data) {
    return createBranch(data)
  },
  UPDATE_BRANCH(_, data) {
    return updateBranch(data)
  },
  DELETE_BRANCH(_, data) {
    return deleteBranch(data)
  },
  FETCH_BRANCH(_, id) {
    return getBranch(id)
  },
  FETCH_BRANCHES_GROUP({ commit }, params) {
    return new Promise((resolve, reject) => {
      getBranchGroup(params)
        .then(response => {
          commit('SET_BRANCHES_GROUP', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_ONE_BRANCHES_GROUP({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneBranchGroup(params)
        .then(response => {
          commit('SET_ONE_BRANCHES_GROUP', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_BRANCHES_FOR_GROUP({ commit }, params) {
    return new Promise((resolve, reject) => {
      getBranchForGroup(params)
        .then(response => {
          commit('SET_BRANCHES_FOR_GROUP', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  CREATE_BRANCHES_GROUP(_, data) {
    return createBranchGroup(data)
  },
  UPDATE_BRANCHES_GROUP(_, data) {
    return updateBranchGroup(data)
  },
  DELETE_BRANCHES_GROUP(_, data) {
    return deleteBranchGroup(data)
  },
}
