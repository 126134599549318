export default [
  {
    path: '/test-base',
    name: 'test-base',
    component: () => import('@/views/modules/Test/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'tests.tests',
          i18n: 'tests.tests',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager'],
    },
  },
  {
    path: '/test-base/:folder_id/add-question/:type',
    name: 'testing-add-question',
    component: () => import('@/views/modules/Test/addOrEditTest.vue'),
    props: {
      isEdit: false,
    },
    meta: {
      breadcrumb: [
        {
          text: 'tests.tests',
          active: false,
          to: '/test-base',
        },
        {
          text: 'tests.add_test',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager'],
    },
  },
  {
    path: '/test-base/:id/edit-question',
    name: 'testing-edit-question',
    component: () => import('@/views/modules/Test/addOrEditTest.vue'),
    props: {
      isEdit: true,
    },
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'tests.tests',
          active: false,
          to: '/test-base',
        },
        {
          text: '',
          active: true,
        },
        {
          text: 'tests.edit_test',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager', 'supervisor'],
    },
  },
  {
    path: '/test-attach-lesson',
    name: 'test-attach-lesson',
    component: () => import('@/views/modules/Courses/AddTest/attachmentPage.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'tests.tests',
          i18n: 'tests.tests',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager'],
    },
  },
  {
    path: '/test-preview/:id',
    name: 'test-preview',
    component: () => import('@/views/modules/Test/components/testPreview.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'tests.tests',
          i18n: 'tests.tests',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager', 'supervisor'],
    },
  },
]
