import {
  getQuestionBase,
  createQuestionFolder,
  updateQuestionFolder,
  getOneQuestionFolder,
  createQuestion,
  getOneQuestion,
  updateOneQuestion,
  deleteQuestion,
  deleteQuestionFolder,
  getQuestionsInfo,
  copyTest,
} from "@/api/questions";

export default {
  FETCH_QUESTION_BASE({ commit }, data) {
    return new Promise((resolve, reject) => {
      getQuestionBase(data)
        .then((response) => {
          commit("SET_QUESTION_BASE", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  FETCH_QUESTION_BASE_TO_MOVE({ commit }, data) {
    return new Promise((resolve, reject) => {
      getQuestionBase(data)
        .then((response) => {
          commit("SET_QUESTION_BASE_TO_MOVE", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  ADD_QUESTION_FOLDER(_, data) {
    return createQuestionFolder(data);
  },
  EDIT_QUESTION_FOLDER(_, data) {
    return updateQuestionFolder(data);
  },
  FETCH_ONE_QUESTION_FOLDER(_, data) {
    return getOneQuestionFolder(data);
  },
  ADD_QUESTION(_, data) {
    return createQuestion(data);
  },
  FETCH_ONE_QUESTION({ commit }, data) {
    return new Promise((resolve, reject) => {
      getOneQuestion(data)
        .then((response) => {
          commit("SET_ONE_QUESTION", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  EDIT_QUESTION(_, data) {
    return updateOneQuestion(data);
  },
  DELETE_QUESTION(_, data) {
    return deleteQuestion(data);
  },
  DELETE_QUESTION_FOLDER(_, data) {
    return deleteQuestionFolder(data);
  },
  COPY_TEST(_, data) {
    return copyTest(data);
  },
  FETCH_QUESTIONS_INFO({ commit }, id) {
    return new Promise((resolve, reject) => {
      getQuestionsInfo(id)
        .then((response) => {
          commit("SET_QUESTIONS_INFO", response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
