export default {
  question_base: [],
  question_base_to_move: [],
  one_question: {},
  test_base_params: {
    search: '',
    parent: null,
  },
  breadcrumb: [{
    title: 'Home',
    id: 0,
    active: true,
  }],
  questions_info: [],
}
