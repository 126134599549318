import http from '@/libs/axios'

export function getOneContact(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/`,
  })
}
export function updateContact(data) {
  return http({
    method: 'PATCH',
    url: `/contacts/contact/${data.id || data.get('id')}/`,
    data,
  })
}
export function getStudentCourses({ id, ...rest }) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/courses_relation/`,
    params: { ...rest },
  })
}
export function getStudentCourse({ id, ...params }) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/courses/`,
    params: { ...params },
  })
}
export function getStudentClasses(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/groups/`,
  })
}

export function getSubjectsOfContact(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/subjects/`,
  })
}

export function getTeacherGroup(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/teacher_groups/`,
  })
}

export function getStudentCourseStatistics(id) {
  return http({
    method: 'GET',
    url: `/contacts/contact/${id}/chapter_statistics/`,
  })
}
