import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from './users'
import courses from './courses'
import classes from './classes'
import content from './content'
import breadcrumb from './breadcrumb'
import contacts from './contacts'
import knowledgeBase from './knowledgeBase'
import branches from './branches'
import questions from './questions'
import finance from './finance'
import files from './files'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    courses,
    classes,
    content,
    breadcrumb,
    contacts,
    knowledgeBase,
    finance,
    branches,
    questions,
    files,
  },
  strict: process.env.DEV,
})
