import Vue from 'vue'
import VueRouter from 'vue-router'
import users from './users'
import classes from './classes'
import courses from './courses'
import branches from './branches'
import references from './references'
import testing from './testing'
import finance from './finance'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
import student from './student'
import chat from './chat'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...users,
    ...classes,
    ...courses,
    ...branches,
    ...references,
    ...testing,
    ...student,
    ...finance,
    ...chat,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'others.home_page',
            active: true,
          },
        ],
        action: 'read',
        resource: ['all'],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: ['Auth'],
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: ['all'],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!isLoggedIn && to.name !== 'login') {
    next('/login')
  } else if (!canNavigate(to)) {
    next({ name: 'error-404' })
  } else {
    return next()
  }
  // if (!canNavigate(to)) {
  //   console.log('not_author')
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) {
  //     console.log('not logged in')
  //     next('/login')
  //   } else {
  //     // If logged in => not authorized
  //     next({ name: 'error-404' })
  //   }
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
    // next({ name: userData.profile.home_route ? userData.profile.home_route : 'inspected-objects' })
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
