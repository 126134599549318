export default [
  {
    path: '/branches-list',
    name: 'branchesList',
    component: () => import('@/views/modules/Branches/BranchesList/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.list_branches',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager'],
    },
  },
  {
    path: '/branches-moderation',
    name: 'branchesModeration',
    component: () => import('@/views/modules/Branches/BranchesModeration/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.moderations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/branches-list/:id',
    name: 'branchUsersList',
    component: () => import('@/views/modules/Branches/BranchesList/UsersList.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.list_branches',
          active: false,
          to: '/branches-list',
        },
        {
          text: 'others.users_list',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager'],
    },
  },
  {
    path: '/branches-group',
    name: 'branchesGroups',
    component: () => import('@/views/modules/Branches/BranchesGroup/BranchesGroup.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.branches_group',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager'],
    },
  },
  {
    path: '/branches-group/:id',
    name: 'branchesGroupsDetail',
    component: () => import('@/views/modules/Branches/BranchesGroup/components/GroupDetail.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.branches_group',
          active: false,
          to: '/branches-group',
        },
        {
          text: 'others.users_list',
          active: true,
        },
      ],
      action: 'read',
      resource: ['head_manager'],
    },
  },
]
