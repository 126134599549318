export default [
  {
    path: '/users/employees',
    name: 'employees',
    component: () => import('@/views/modules/Users/Employees/Employees.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.employees_list',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'head_manager', 'id_manager'],
    },
  },
  {
    path: '/users/teachers',
    name: 'teachers',
    component: () => import('@/views/modules/Users/Teachers/Teachers.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.teachers_list',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'id_manager', 'supervisor', 'head_manager'],
    },
  },
  {
    path: '/users/students',
    name: 'pupils',
    component: () => import('@/views/modules/Users/Students/Students.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.students_list',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'id_manager', 'supervisor', 'head_manager'],
    },
  },
  // {
  //   path: '/users/user/:id',
  //   name: 'add-edit-user',
  //   component: () => import('@/components/AddEdit.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'others.users',
  //         active: false,
  //         to: '/users/user/',
  //       },
  //       {
  //         text: 'Добавить и Редактировать ползователя',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/users/user',
    name: 'user',
    component: () => import('@/views/modules/Users/User/User.vue'),
    meta: {
      // pageTitle: 'Second Page',
      breadcrumb: [
        {
          text: 'others.users_list',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'head_manager'],
    },
  },
  {
    path: '/users/:id/info',
    name: 'user-contact-info',
    component: () => import('@/views/modules/Users/detail.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.users',
          active: false,
          to: '/users/students',
        },
        {
          text: 'others.username',
          active: true,
        },
        {
          text: 'others.user_data',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'id_manager', 'supervisor', 'head_manager', 'teacher'],
    },
  },
  // {
  //   path: '/student-detail/:id/',
  //   name: 'student-courses-info',
  //   component: () => import('@/views/modules/Home/Teacher/openStudentInfo/StudentInfo.vue'),
  //   meta: {
  //     is_dynamic_route: true,
  //     breadcrumb: [
  //       {
  //         text: 'classes.students',
  //         active: false,
  //         to: '/',
  //       },
  //       {
  //         text: '',
  //         active: true,
  //       },
  //     ],
  //     action: 'read',
  //     resource: ['admin', 'id_manager', 'supervisor', 'head_manager'],
  //   },
  // },
  // {
  //   path: '/users/students/info/:id/theme-list/:themeId',
  //   name: 'theme-list',
  //   component: () => import('@/views/modules/Users/Students/infoPage/tabs/ThemeList.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Список учеников',
  //         active: false,
  //         to: '/users/students',
  //       },
  //       {
  //         text: 'Данние ученика',
  //         active: false,
  //         to: '/users/students',
  //       },
  //       {
  //         text: 'Список тем',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/student-info/class-list',
  //   name: 'student-info-class-list',
  //   component: () => import('@/views/modules/StudentInfo/ClassList.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Класс листь',
  //         active: false,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/student-info/course-list',
  //   name: 'student-info-course-list',
  //   component: () => import('@/views/modules/StudentInfo/CourseList.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Курс листь',
  //         active: false,
  //       },
  //     ],
  //   },
  // },
]
