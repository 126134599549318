import configs from '../../../../axios.config'

export default {
  // Endpoints
  loginEndpoint: '/users/login',
  registerEndpoint: '/users/register',
  refreshEndpoint: '/users/login/refresh',
  logoutEndpoint: '/users/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: `access${configs.token_prefix}`,
  storageRefreshTokenKeyName: `refresh${configs.token_prefix}`,
  storageUserDataKeyName: `userData${configs.token_prefix}`,
  storageAbilityKeyName: `ability${configs.token_prefix}`,
}
