export default [
  {
    path: '/course/form-types/:id/:relationId',
    name: 'user-course-form-types',
    component: () => import('@/views/modules/Home/StudentCourseDetail/StudentCourseFormTypes.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'users.courses',
          active: false,
          to: '/',
        },
        {
          text: 'Question types',
          active: true,
        },
        {
          text: 'others.form_types',
          active: true,
        },
      ],
      action: 'read',
      resource: ['student'],
    },
  },
  {
    path: '/course/:id/:relationId/form/:formType',
    name: 'user-courses-by-form-type',
    component: () => import('@/views/modules/Home/StudentCourseDetail/StudentCourseDetail.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'users.courses',
          active: false,
          to: '/',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
      ],
      action: 'read',
      resource: ['student'],
    },
  },
  {
    path: '/course/:id/:relationId',
    name: 'user-courses',
    component: () => import('@/views/modules/Home/StudentCourseDetail/StudentCourseDetail.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'users.courses',
          active: false,
          to: '/',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
      ],
      action: 'read',
      resource: ['student', 'head_manager', 'supervisor'],
    },
  },
  {
    path: '/course/:id/view/:themeId',
    name: 'user-course-themes',
    component: () => import('@/views/modules/Home/StudentCourseDetail/ViewContentOrTest/ViewContentOrTest.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'users.courses',
          active: false,
          to: '/',
        },
        {
          text: 'others.list_theme',
          active: true,
        },
        {
          text: 'others.contents',
          active: true,
        },
      ],
      action: 'read',
      resource: ['student', 'head_manager', 'teacher', 'supervisor'],
    },
  },
  {
    path: '/settlements',
    name: 'settlements',
    component: () => import('@/views/modules/Home/TransactionDetail/Settlements.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'users.mutual_settlements',
          i18n: 'users.mutual_settlements',
          active: true,
        },
      ],
      action: 'read',
      resource: ['student', 'head_manager'],
    },
  },
  {
    path: '/student/:relationId/course/:id/progress/:studentId',
    name: 'student-course-progress',
    component: () => import('@/views/modules/Home/StudentCourseDetail/StudentCourseDetail.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'users.progress_student',
          i18n: 'users.progress_student',
          active: false,
        },
        {
          text: 'others.list_theme',
          active: true,
        },
      ],
      action: 'read',
      resource: ['teacher', 'admin', 'head_manager', 'supervisor'],
    },
  },
]
