import {
  updateContact,
  getStudentCourse,
  getStudentClasses,
  getStudentCourses,
  getSubjectsOfContact,
  getOneContact,
  getTeacherGroup,
  getStudentCourseStatistics,
} from '@/api/contacts'

export default {
  EDIT_ONE_CONTACT(_, data) {
    return updateContact(data)
  },
  FETCH_STUDENT_COURSE({ commit }, data) {
    return new Promise((resolve, reject) => {
      getStudentCourse(data)
        .then(response => {
          commit('SET_STUDENT_COURSE', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_CONTACT(_, data) {
    return new Promise((resolve, reject) => {
      getOneContact(data)
        .then(response => {
          // commit('SET_STUDENT_COURSE', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_STUDENT_CLASSES({ commit }, data) {
    return new Promise((resolve, reject) => {
      getStudentClasses(data)
        .then(response => {
          commit('SET_STUDENT_CLASSES', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_COURSES_BY_CONTACT({ commit }, data) {
    return new Promise((resolve, reject) => {
      getStudentCourses(data).then(resp => {
        if (data.page_size) {
          if (data.page === 1) commit('SET_COURSES_BY_CONTACT', { results: [] })
          commit('SET_COURSES_BY_CONTACT_LAZY', resp.data)
        } else {
          commit('SET_COURSES_BY_CONTACT', resp.data)
        }
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  FETCH_SUBJECTS_OF_CONTACT({ commit }, data) {
    return new Promise((resolve, reject) => {
      getSubjectsOfContact(data).then(resp => {
        commit('SET_SUBJECTS_OF_CONTACT', resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  FETCH_TEACHERS_GROUPS({ commit }, data) {
    return new Promise((resolve, reject) => {
      getTeacherGroup(data).then(resp => {
        commit('SET_TEACHER_GROUPS', resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  FETCH_CONTACT_COURSE_STATS({ commit }, data) {
    return new Promise((resolve, reject) => {
      getStudentCourseStatistics(data).then(res => {
        commit('SET_STUDENT_COURSE_STATS', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
