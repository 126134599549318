import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const ability = JSON.parse(localStorage.getItem(jwtDefaultConfig.storageAbilityKeyName))
// const existingAbility = userData ? userData.ability : null
export default new Ability(ability || initialAbility)
