import {
  createSubject,
  getSubjects,
  updateSubject,
  getGroupsList,
  createGroupsList,
  deleteGroups,
  updateGroups,
  attachSubjectsToTeacher,
  getAdministration,
  createAdministration,
  updateAdministration,
  getUsersListForTags,
  getOneGroups,
  addUsersInTag,
  getUsersInTag,
  deleteUsersInTag,
} from '@/api/knowledgeBase'

export default {
  FETCH_SUBJECTS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getSubjects(params)
        .then(response => {
          commit('SET_SUBJECTS_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  EDIT_SUBJECT(_, data) {
    return updateSubject(data)
  },
  ADD_SUBJECT(_, data) {
    return createSubject(data)
  },

  // groups
  FETCH_GROUPS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getGroupsList(params)
        .then(response => {
          commit('SET_GROUPS_LIST', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  ADD_USERS_IN_GROUP(_, data) {
    return addUsersInTag(data)
  },
  FETCH_USER_IN_GROUP({ commit }, params) {
    return new Promise((resolve, reject) => {
      getUsersInTag(params)
        .then(response => {
          commit('SET_USER_IN_GROUP', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  DELETE_USER_IN_GROUP(_, id) {
    return deleteUsersInTag(id)
  },
  FETCH_ONE_GROUP(_, id) {
    return getOneGroups(id)
  },
  CREATE_GROUPS_LIST(_, data) {
    return createGroupsList(data)
  },

  DELETE_GROUPS_LIST(_, id) {
    return deleteGroups(id)
  },

  UPDATE_GROUPS(_, data) {
    return updateGroups(data)
  },

  ATTACH_SUBJECTS_TO_TEACHER(_, data) {
    return attachSubjectsToTeacher(data)
  },

  // administration
  FETCH_ADMINISTRATION({ commit }, params) {
    return new Promise((resolve, reject) => {
      getAdministration(params)
        .then(response => {
          commit('SET_ADMINISTRATION', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  CREATE_ADMINISTRATION(_, data) {
    return createAdministration(data)
  },

  UPDATE_ADMINISTRATION(_, data) {
    return updateAdministration(data)
  },

  FETCH_USERS_LIST_TAGS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getUsersListForTags(params)
        .then(response => {
          commit('SET_USERS_LIST_TAGS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
