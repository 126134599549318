export default {
  usersList: {
    results: [],
  },
  users_list_chat: {
    results: [],
  },
  usersRolesList: [],
  employeeList: [],
  teachersList: [],
  pupilsList: [],
  levels_list: [
    {
      id: 1,
      level: '1',
    },
    {
      id: 2,
      level: '2',
    },
    {
      id: 3,
      level: '3',
    },
    {
      id: 4,
      level: '4',
    },
    {
      id: 5,
      level: '5',
    },
    {
      id: 6,
      level: '6',
    },
    {
      id: 7,
      level: '7',
    },
  ],
  class_list: [],
  user: {
    contact: {
      phones: [],
    },
    roles: [],
  },
  user_auth_logs: {
    results: [],
  },
  user_activity: {
    results: [],
  },
  dashboard_statistics: {},
  users_list: {
    results: [],
  },
  teachers_attached_supervisor: {
    results: [],
  },
  teachers_not_attached_supervisor: {
    results: [],
  },
}
