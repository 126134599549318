export default {
  GET_FOLDER_LIST: state => state.foldersList,
  GET_COURSES_LIST: state => state.courses_list,
  GET_COURSES_LIST_SYNC: state => state.courses_list_sync,
  GET_ONE_COURSE: state => state.one_course,
  GET_CHAPTERS_LIST: state => state.chapters_list,
  GET_COURSE_CHAPTERS: state => state.chapters,
  GET_CLASS_COURSE: state => state.classCourse,
  GET_ONE_RELATION: state => state.oneRelation,
  GET_ONE_CHAPTER: state => state.one_chapter,
  GET_LESSON: state => state.lesson,
  GET_LESSONS: state => state.lessons,
  GET_LESSON_CONTENT: state => state.one_chapter.contents,
  GET_QUESTION_BASE: state => state.question_base,
  GET_ONE_CONTENT: state => state.lesson,
  GET_LESSON_VIEW_HISTORY: state => state.lesson_history,
  GET_ONE_LESSON_VIEW_HISTORY: state => state.one_lesson_history,
  GET_ONE_LESSON_HISTORY(state) {
    return state.single_lesson_history
  },
  GET_CHECK_RESULTS(state) {
    return state.check_results
  },
  GET_NOTIFICATIONS(state) {
    return state.notifications
  },
  GET_STUDENTS_IN_COURSE(state) {
    return state.students_in_course
  },
  GET_STUDENTS_IN_CLASS(state) {
    return state.students_in_class
  },
  GET_CLASS_IN_COURSE(state) {
    return state.classInCourse
  },
  GET_FORM_TYPES(state) {
    return state.form_types
  },
}
