export default {
  methods: {
    $_showAreYouSureModal() {
      return this.$bvModal.msgBoxConfirm(this.$t('general.you_are_sure'), {
        title: this.$t('general.pleace_confirm'),
        size: 'sm',
        // buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('general.ok'),
        cancelTitle: this.$t('general.no'),
        // footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
        noCloseOnBackdrop: true,
      })
    },
  },
}
