import http from '@axios'

// get comments list
export function getComments(params) {
  return http({
    method: 'GET',
    url: '/files/comments/',
    params,
  })
}

// create comments
export function createComment(data) {
  return http({
    method: 'POST',
    url: '/files/comments/',
    data,
  })
}

export function getOneComment(id) {
  return http({
    method: 'GET',
    url: `/files/comments/${id}/`,
  })
}

export function updateComment(data) {
  return http({
    method: 'PATCH',
    url: `/files/comments/${data.id}/`,
    data,
  })
}

export function deleteComment(id) {
  return http({
    method: 'DELETE',
    url: `/files/comments/${id}/`,
  })
}
