export default {
  subjects_list: [],
  groups: {
    results: [],
  },
  administration: {},
  users_list_tags: [],
  user_in_group: {
    results: [],
    count: 0,
  },
}
