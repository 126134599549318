export default {
  SET_FOLDERS_LIST(state, data) {
    state.foldersList = data
  },
  SET_COURSES_LIST(state, data) {
    state.courses_list = data
  },
  SET_COURSES_LIST_SYNC(state, data) {
    state.courses_list_sync = {
      ...data,
      results: [...state.courses_list_sync.results, ...data.results],
    }
  },
  SET_ONE_FOLDER(state, data) {
    state.one_folder = data
  },
  SET_ONE_COURSE(state, data) {
    state.one_course = data
  },
  SET_CHAPTERS_LIST(state, data) {
    state.chapters_list = data
  },
  SET_CHAPTERS(state, data) {
    state.chapters = data
  },
  SET_CLASS_COURSE(state, data) {
    state.classCourse = data
  },
  SET_ONE_RELATION(state, data) {
    state.oneRelation = data
  },
  SET_ONE_CHAPTER(state, data) {
    state.one_chapter = data
  },
  SET_LESSONS(state, data) {
    state.lessons = data
  },
  SET_LESSON(state, data) {
    state.lesson = data
  },
  SET_ONE_CONTENT(state, data) {
    state.oneContent = data
  },
  SET_QUESTION_BASE(state, data) {
    state.question_base = data
  },
  SET_LESSON_VIEW_HISTORY(state, data) {
    state.lesson_history = data
  },
  SET_ONE_LESSON_VIEW_HISTORY(state, data) {
    state.one_lesson_history = data
  },
  SET_ONE_LESSON_HISTORY(state, data) {
    state.single_lesson_history = data
  },
  SET_CHECK_RESULTS(state, data) {
    state.check_results = data
  },
  SET_NOTIFICATIONS(state, data) {
    state.notifications = data
  },
  SET_SOCKET_NOTIFICATIONS(state, data) {
    state.notifications.results.push(data)
  },
  SET_STUDENTS_IN_COURSE(state, data) {
    state.students_in_course = data
  },
  SET_STUDENTS_IN_CLASS(state, data) {
    state.students_in_class = data
  },
  SET_COURSES_IN_CLASS(state, data) {
    state.classInCourse = data
  },
  SET_FORM_TYPES(state, data) {
    state.form_types = data
  },
  ADD_FORM_TYPE(state, data) {
    state.form_types.results.unshift(data)
  },
}
