export default {
  SET_CLASS_LIST(state, data) {
    state.classList = data
  },
  ONE_CLASS(state, data) {
    state.oneClass = data
  },
  SET_CLASS_STUDENTS_LIST(state, data) {
    state.class_students = data
  },
}
