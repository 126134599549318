export default {
  SET_USERS_LIST(state, data) {
    state.usersList = data
  },
  SET_ROLES_LIST(state, data) {
    state.usersRolesList = data
  },
  SET_EMPLOYEES_LIST(state, data) {
    state.employeeList = data
  },
  SET_TEACHERS_LIST(state, data) {
    state.teachersList = data
  },
  SET_PUPILS_LIST(state, data) {
    state.pupilsList = data
  },
  SET_LEVELS_LIST(state, data) {
    state.levels_list = data
  },
  SET_CLASS_LIST(state, data) {
    state.class_list = data
  },
  SET_USER(state, data) {
    state.user = data
  },
  SET_USER_AUTH_LOGS(state, data) {
    state.user_auth_logs = data
  },
  SET_USER_ACTIVITY(state, data) {
    state.user_activity = data
  },
  SET_DASHBOARD_STATISTICS(state, data) {
    state.dashboard_statistics = data
  },
  SET_TEACHERS_ATTACHED_SUPERVISOR(state, data) {
    state.teachers_attached_supervisor = data
  },

  SET_TEACHERS_NOT_ATTACHED_SUPERVISOR(state, data) {
    state.teachers_not_attached_supervisor = data
  },

  SET_USERS_ASYNC(state, data) {
    state.users_list = {
      ...data,
      results: [...state.users_list.results, ...data.results],
    }
  },

  SET_USERS_FOR_CHAT_ASYNC(state, data) {
    state.users_list_chat = {
      ...data,
      results: [...state.users_list_chat.results, ...data.results],
    }
  },
}
