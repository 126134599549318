export default [
  {
    path: '/references/subjects',
    name: 'subjects-list',
    component: () => import('@/views/modules/KnowledgeBase/Subjects/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'references.subjects',
          i18n: 'references.subjects',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'head_manager'],
    },
  },
  {
    path: '/administrations',
    name: 'web-settings',
    component: () => import('@/views/modules/KnowledgeBase/Administrations/Administrations.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.login_administration',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'head_manager'],
    },
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/modules/KnowledgeBase/Groups/index.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'others.tags',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'head_manager', 'id_manager', 'supervisor'],
    },
  },
  {
    path: '/groups/:id',
    name: 'groups-detail',
    component: () => import('@/views/modules/KnowledgeBase/Groups/components/TagDetail.vue'),
    meta: {
      is_dynamic_route: true,
      breadcrumb: [
        {
          text: 'others.tags',
          active: false,
          to: '/groups',
        },
        {
          text: 'detail',
          active: true,
        },
      ],
      action: 'read',
      resource: ['admin', 'head_manager', 'id_manager', 'supervisor'],
    },
  },
]
