import {
  getUsersList,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  getUsersRolesList,
  getEmployeesList,
  getTeachersList,
  getPupilsList,
  getUserActivity,
  getDashboardStatistics,
  getUsersForChat,
  importUsersFromFile,
  getTeachersAttachedSupervisor,
  getTeachersNotAttachedSupervisor,
  updateTeachersSupervisor,
  deleteTeachersSupervisor,
  exportUsersExcel,
} from '@/api/users'

export default {
  FETCH_USERS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getUsersList(params)
        .then(response => {
          commit('SET_USERS_LIST', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_USER({ commit }, params) {
    return new Promise((resolve, reject) => {
      getUser(params)
        .then(response => {
          commit('SET_USER', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  CREATE_USER(_, data) {
    // return new Promise((resolve, reject) => {
    return createUser(data)
    // .then(response => {
    // commit('SET_USERS_LIST', response.data)
    // resolve(response.data)
    // })
    // .catch(error => {
    // reject(error)
    // })
    // })
  },
  UPDATE_USER(_, data) {
    return updateUser(data)
  },
  DELETE_USER(_, id) {
    return new Promise((resolve, reject) => {
      deleteUser(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_USER_ROLES_LIST({ commit }) {
    return new Promise((resolve, reject) => {
      getUsersRolesList()
        .then(res => {
          commit('SET_ROLES_LIST', res.data)
          resolve(res.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  FETCH_EMPLOYEES_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getEmployeesList(params)
        .then(response => {
          commit('SET_EMPLOYEES_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_TEACHERS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getTeachersList(params)
        .then(response => {
          commit('SET_TEACHERS_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_PUPILS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getPupilsList(params)
        .then(response => {
          commit('SET_PUPILS_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_USER_ACTIVITY({ commit }, data) {
    return new Promise((resolve, reject) => {
      getUserActivity(data).then(resp => {
        commit('SET_USER_ACTIVITY', resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  FETCH_DASHBOARD_STATISTICS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getDashboardStatistics(params).then(resp => {
        commit('SET_DASHBOARD_STATISTICS', resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  FETCH_USERS_ASYNC({ commit }, params) {
    return getUsersList(params).then(res => {
      if (!params.search) {
        commit('SET_USERS_ASYNC', res.data)
      }
      return res.data
    })
  },

  FETCH_USERS_FOR_CHAT() {
    return getUsersForChat()
      .then(res => res.data)
    // commit('SET_USERS_FOR_CHAT_ASYNC', res.data)
  },

  IMPORT_USERS_FROM_FILE(_, data) {
    return importUsersFromFile(data)
  },
  EXPORT_USERS_FROM_FILE(_, params) {
    return exportUsersExcel(params)
  },
  FETCH_TEACHERS_ATTACHED_SUPERVISOR({ commit }, params) {
    return new Promise((resolve, reject) => {
      getTeachersAttachedSupervisor(params).then(resp => {
        commit('SET_TEACHERS_ATTACHED_SUPERVISOR', resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  FETCH_TEACHERS_NOT_ATTACHED_SUPERVISOR({ commit }, params) {
    return new Promise((resolve, reject) => {
      getTeachersNotAttachedSupervisor(params).then(resp => {
        commit('SET_TEACHERS_NOT_ATTACHED_SUPERVISOR', resp.data)
        resolve(resp.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  UPDATE_TEACHERS_NOT_ATTACHED_SUPERVISOR(_, data) {
    return updateTeachersSupervisor(data)
  },
  DELETE_TEACHERS_NOT_ATTACHED_SUPERVISOR(_, data) {
    return deleteTeachersSupervisor(data)
  },
}
