import {
  getCoursesList,
  createCoursesList,
  deleteCourse,
  updateCoursesList,
  getOneCourse,
  CreateChapter,
  DeleteChapter,
  UpdateChapter,
  createClassCourse,
  getClassCourse,
  deleteClassCourse,
  updateClassCourse,
  getOneRelation,
  getLesson,
  deleteLesson,
  updateLesson,
  createLesson,
  getOneChapter,
  createLessonContent,
  getlessonContent,
  updateLessonContent,
  getOneLesson,
  deleteOnelessonContent,
  getOnelessonContent,
  copyCourse,
  createLessonViewHistory,
  getLessonViewHistory,
  updateLessonViewHistory,
  getOneLessonViewHistory,
  getOneLessonHistory,
  checkTests,
  getOneCourseForAdmin,
  getNotificationsList,
  getOneLessonViewHistoryForAdmin,
  getChapterList,
  importCourseOrLesson,
  getStudentsInCourse,
  getCoursesInClass,
  updateCourseRelation,
  updateLessonDraft,
  getStudentsInClass,
  exportStudentsResult,
  getFormTypes,
  createFormType,
  updateFormType,
  deleteFormType,
  changeFormTypePosition,
} from '@/api/courses'

export default {
  FETCH_COURSES_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getCoursesList(params)
        .then(response => {
          commit('SET_COURSES_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_COURSES_LIST_SYNC({ commit }, params) {
    return new Promise((resolve, reject) => {
      getCoursesList(params)
        .then(response => {
          if (!params.search) {
            commit('SET_COURSES_LIST_SYNC', response.data)
          }
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_COURSE({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneCourse(params)
        .then(response => {
          commit('SET_ONE_COURSE', response.data)
          commit('SET_CHAPTERS', response.data.chapters)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_COURSE_FOR_ADMIN({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneCourseForAdmin(params)
        .then(response => {
          commit('SET_ONE_COURSE', response.data)
          commit('SET_CHAPTERS', response.data.chapters)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  CREATE_COURSE(_, data) {
    return new Promise((resolve, reject) => {
      createCoursesList(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_COURSE(_, data) {
    return updateCoursesList(data)
  },
  DELETE_COURSE(_, id) {
    return deleteCourse(id)
  },
  // chapter
  CREATE_CHAPTER(_, data) {
    return CreateChapter(data)
  },
  FETCH_CHAPTERS_LIST({ commit }, params) {
    return new Promise((resolve, reject) => {
      getChapterList(params)
        .then(response => {
          commit('SET_CHAPTERS_LIST', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_CHAPTER({ commit }, id) {
    return new Promise((resolve, reject) => {
      getOneChapter(id)
        .then(response => {
          commit('SET_ONE_CHAPTER', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  DELETE_CHAPTER(_, data) {
    return DeleteChapter(data)
  },
  UPDATE_CHAPTER(_, data) {
    return UpdateChapter(data)
  },
  // cgt
  CREATE_CLASS_COURSE(_, data) {
    return createClassCourse(data)
  },
  FETCH_CLASS_COURSE({ commit }, params) {
    return new Promise((resolve, reject) => {
      getClassCourse(params)
        .then(response => {
          commit('SET_CLASS_COURSE', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_RELATION({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneRelation(params)
        .then(response => {
          commit('SET_ONE_RELATION', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  DELETE_CLASS_COURSE(_, id) {
    return deleteClassCourse(id)
  },
  UPDATE_CLASS_COURSE(_, data) {
    return updateClassCourse(data)
  },
  // lessons
  FETCH_LESSONS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getLesson(params)
        .then(response => {
          commit('SET_LESSONS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_LESSON({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneLesson(params)
        .then(response => {
          commit('SET_LESSON', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_LESSON(_, data) {
    return updateLesson(data)
  },
  DELETE_LESSON(_, id) {
    return deleteLesson(id)
  },
  CREATE_LESSON(_, data) {
    return createLesson(data)
  },
  // COntents
  FETCH_LESSON_CONTENT({ commit }, params) {
    return new Promise((resolve, reject) => {
      getlessonContent(params)
        .then(response => {
          commit('SET_CONTENTS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_CONTENT({ commit }, id) {
    return new Promise((resolve, reject) => {
      getOnelessonContent(id)
        .then(response => {
          commit('SET_ONE_CONTENT', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  CREATE_LESSON_CONTENT(_, data) {
    return createLessonContent(data)
  },
  UPDATE_LESSON_CONTENT(_, data) {
    return updateLessonContent(data)
  },
  DELETE_LESSON_CONTENT(_, id) {
    return deleteOnelessonContent(id)
  },
  COPY_COURSE(_, id) {
    return copyCourse(id)
  },
  CREATE_LESSON_VIEW_HISTORY(_, data) {
    return createLessonViewHistory(data)
  },
  FETCH_LESSON_VIEW_HISTORY({ commit }, params) {
    return new Promise((resolve, reject) => {
      getLessonViewHistory(params)
        .then(response => {
          commit('SET_LESSON_VIEW_HISTORY', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_LESSON_VIEW_HISTORY({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneLessonViewHistory(params)
        .then(response => {
          commit('SET_ONE_LESSON_VIEW_HISTORY', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_ONE_LESSON_VIEW_HISTORY_FOR_ADMIN({ commit }, params) {
    return new Promise((resolve, reject) => {
      getOneLessonViewHistoryForAdmin(params)
        .then(response => {
          commit('SET_ONE_LESSON_VIEW_HISTORY', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_LESSON_VIEW_HISTORY(_, data) {
    return updateLessonViewHistory(data)
  },
  FETCH_ONE_LESSON_HISTORY({ commit }, data) {
    return new Promise((resolve, reject) => {
      getOneLessonHistory(data)
        .then(response => {
          commit('SET_ONE_LESSON_HISTORY', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  CHECK_TEST({ commit }, data) {
    return new Promise((resolve, reject) => {
      checkTests(data)
        .then(response => {
          commit('SET_CHECK_RESULTS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_NOTIFICATIONS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getNotificationsList(params)
        .then(response => {
          commit('SET_NOTIFICATIONS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  IMPORT_CHAPTER_OR_LESSON(_, data) {
    return importCourseOrLesson(data)
  },
  FETCH_STUDENTS_IN_COURSE({ commit }, params) {
    return new Promise((resolve, reject) => {
      getStudentsInCourse(params)
        .then(response => {
          commit('SET_STUDENTS_IN_COURSE', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_STUDENTS_IN_CLASS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getStudentsInClass(params)
        .then(response => {
          commit('SET_STUDENTS_IN_CLASS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_COURSES_IN_CLASS({ commit }, params) {
    return new Promise((resolve, reject) => {
      getCoursesInClass(params)
        .then(response => {
          commit('SET_COURSES_IN_CLASS', response.data)
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_COURSE_RELATION(_, data) {
    return updateCourseRelation(data)
  },
  UPDATE_LESSON_DRAFT(_, data) {
    return updateLessonDraft(data)
  },
  EXPORT_RESULTS_STUDENT(_, data) {
    return exportStudentsResult(data)
  },
  FETCH_FORM_TYPES({ commit }, params) {
    return new Promise((resolve, reject) => {
      getFormTypes(params).then(res => {
        commit('SET_FORM_TYPES', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CREATE_FORM_TYPE({ commit }, data) {
    return new Promise((resolve, reject) => {
      createFormType(data).then(res => {
        commit('ADD_FORM_TYPE', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  UPDATE_FORM_TYPE(_, data) {
    return updateFormType(data)
  },
  DELETE_FORM_TYPE(_, id) {
    return deleteFormType(id)
  },
  CHANGE_POSITION_FORM_TYPE(_, data) {
    return changeFormTypePosition(data)
  },
}
