export default {
  methods: {
    $_saveFilter(filter, name = this.$route.path) {
      sessionStorage.setItem(name, JSON.stringify(filter))
      console.log('$_saveFilter', name, filter)
    },
    $_getFilter(name = this.$route.path) {
      console.log('$_getFilter', name)
      return JSON.parse(sessionStorage.getItem(name))
    },
  },
}
