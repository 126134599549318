export default {
  SET_TRANSACTIONS(state, data) {
    state.transactions = data
  },
  SET_INCOME_STATISTICS_LIST(state, data) {
    state.incomeStatistics = data
  },
  SET_USER_TRANSACTION(state, data) {
    state.userTransactions = data
  },
  SET_USER_TRANSACTION_HISTORY(state, data) {
    state.userTransactionsHistory = data
  },
  SET_CLIENTS_LIST(state, data) {
    state.clientsList = {
      ...data,
      results: [...state.clientsList.results, ...data.results],
    }
  },
  SET_CLIENTS_LIST_FOR_FILTER(state, data) {
    state.clientsList2 = {
      ...data,
      results: [...state.clientsList2.results, ...data.results],
    }
  },
}
