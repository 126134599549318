export default {
  SET_QUESTION_BASE(state, data) {
    state.question_base = data
  },
  SET_QUESTION_BASE_TO_MOVE(state, data) {
    state.question_base_to_move = data
  },
  SET_ONE_QUESTION(state, data) {
    state.one_question = data
  },
  SET_TEST_BASE_PARAMS(state, data) {
    state.test_base_params = data
  },
  SET_BREADCRUMB(state, data) {
    const { type, ...rest_data } = data
    if (type === 'enter') {
      state.breadcrumb = state.breadcrumb.map(({ active, ...rest }) => ({ ...rest, active: false }))
      state.breadcrumb.push({ ...rest_data, active: true })
    } else if (type === 'toBack') {
      const index = state.breadcrumb.map(({ id }) => (id)).indexOf(data.id)
      if (index === -1) return
      state.breadcrumb = state.breadcrumb.splice(0, index + 1)
      state.breadcrumb.at(-1).active = true
    }
  },
  SET_QUESTIONS_INFO(state, data) {
    state.questions_info = data
  },
}
