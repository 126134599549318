export default {
  SET_SUBJECTS_LIST(state, data) {
    state.subjects_list = data
  },
  SET_GROUPS_LIST(state, data) {
    state.groups = data
  },
  SET_ADMINISTRATION(state, data) {
    state.administration = data
  },
  SET_USERS_LIST_TAGS(state, data) {
    state.users_list_tags = data
  },
  SET_USER_IN_GROUP(state, data) {
    state.user_in_group = data
  },
}
