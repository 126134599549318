import http from '@axios'

export function getGroupsList(params) {
  return http.get('/knowledge_base/tag/', { params })
}
export function getOneGroups(id) {
  return http.get(`/knowledge_base/tag/${id}/`)
}

// лист юзеров
export function getUsersListForTags(params) {
  return http.get('/knowledge_base/tag-user/get_user_for_add/', { params })
}

export function addUsersInTag(data) {
  return http.post('/knowledge_base/tag-user/', data)
}

export function deleteUsersInTag(id) {
  return http.delete(`/knowledge_base/tag-user/${id}/`)
}

export function getUsersInTag(params) {
  return http.get('/knowledge_base/tag-user/', { params })
}

export function createGroupsList(data) {
  return http.post('/knowledge_base/tag/', data)
}

export function deleteGroups(id) {
  return http.delete(`/knowledge_base/tag/${id}/`)
}

export function updateGroups(data) {
  return http.patch(`/knowledge_base/tag/${data.id}/`, data)
}

export function getSubjects(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/subject/',
    params,
  })
}

export function createSubject(data) {
  return http({
    method: 'POST',
    url: '/knowledge_base/subject/',
    data,
  })
}

export function updateSubject(data) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/subject/${data.id}/`,
    data,
  })
}

export function getTeacherAttachedSubjects(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/subject_teacher_relation/',
    params,
  })
}

export function attachSubjectsToTeacher(data) {
  return http({
    method: 'POST',
    url: '/knowledge_base/subject_teacher_relation/',
    data,
  })
}

export function getAdministration(params) {
  return http({
    method: 'GET',
    url: '/knowledge_base/login_view/',
    params,
  })
}

export function createAdministration(data) {
  return http({
    method: 'POST',
    url: '/knowledge_base/login_view/',
    data,
  })
}

export function updateAdministration(data) {
  return http({
    method: 'PATCH',
    url: `/knowledge_base/login_view/${data.id}/`,
    data,
  })
}
