export default {
  data() {
    return {
      $_gender_options: [
        {
          key: 'male',
          title: {
            uz: 'Erkak',
            ru: 'Мужской',
            en: 'Male',
          },
        },
        {
          key: 'female',
          title: {
            uz: 'Ayol',
            en: 'Female',
            ru: 'Женский',
          },
        },
      ],
      $_test_type_options: [
        {
          key: 'single-select',
          value: 's',
          component: 'b-form-radio-group',
          title: {
            en: 'Single select',
            ru: 'Одиночный выбор',
            uz: 'Bittalik tanlov',
          },
        },
        {
          key: 'multiple-select',
          value: 'm',
          component: 'b-form-checkbox-group',
          title: {
            en: 'Multiple select',
            ru: 'Множественный выбор',
            uz: 'Bir nechtalik tanlov',
          },
        },
        {
          key: 'essay',
          value: 'e',
          component: 'div',
          title: {
            en: 'Essay',
            ru: 'Сочинения',
            uz: 'Insho',
          },
        },
        {
          key: 'arbitrary choice',
          value: 'a',
          component: 'b-form-radio-group',
          title: {
            en: 'Arbitrary choice',
            ru: 'Произвольный выбор',
            uz: 'Erkin tanlov',
          },
        },
        {
          key: 'gap filling',
          value: 'g',
          component: 'div',
          title: {
            en: 'Gap filling',
            ru: 'Заполнение пробелов',
            uz: 'Bo\'shliqlarni to\'ldirish',
          },
        },
      ],
      $_test_report_form_options: [
        {
          key: 'with_correct_answers',
          label: {
            uz: "To'g'ri javoblari bilan",
            en: 'With correct answers',
            ru: 'С правильными ответами',
          },
        },
        {
          key: 'incorrect_answers',
          label: {
            uz: "Noto'g'ri javoblar",
            en: 'Incorrect answers only',
            ru: 'Показать неправильные ответы',
          },
        },
        {
          key: 'score',
          label: {
            uz: "Faqat ballni ko'rsatish",
            en: 'Show only score',
            ru: 'Показать только балл',
          },
        },
      ],
      $_test_presentation_options: [
        {
          key: 'single',
          label: {
            uz: 'Bitta-bitta',
            en: 'One by one',
            ru: 'По одному',
          },
        },
        {
          key: 'multiple',
          label: {
            uz: 'Hammasi bittada',
            en: 'All in one',
            ru: 'Все в одном',
          },
        },
      ],
      $_lessons_view_order: [
        {
          key: 'free',
          title: {
            uz: 'Boshlang\'ich shart belgilanmagan',
            ru: 'Не устанавливать в качестве предварительного условия',
            en: 'Do not set as prerequisite',
          },
        },
        {
          key: 'previous',
          title: {
            uz: 'Oldingi qadam tugamaguncha kirishni oldini olish',
            ru: 'Запретить доступ до завершения предыдущего шага',
            en: 'Prevent access until previous step is completed',
          },
        },
        {
          key: 'all_previous',
          title: {
            uz: 'Oldingi barcha qadamlar tugamaguncha kirishni oldini oling',
            ru: 'Запретить доступ до тех пор, пока не будут выполнены все предыдущие шаги',
            en: 'Prevent access until all previous steps is completed',
          },
        },
      ],
    }
  },
}
