import http from '@/libs/axios'

// лист юзеров
export function getUsersList(params) {
  return http.get('/users/user/', { params })
}
// деталы юзера
export function getUser(id) {
  return http.get(`/users/user/${id}/`)
}

// Создать юзера
export function createUser(data) {
  return http.post('/users/user/', data)
}

// Редактировать юзера
export function updateUser(data) {
  return http.patch(`/users/user/${data.id || data.get('id')}/`, data)
}

// удаление юзера
export function deleteUser(id) {
  return http.delete(`/users/user/${id}/`)
}

// лист ролов
export function getUsersRolesList() {
  return http.get('/users/role/')
}

// лист уровенов
export function getUsersLevelsList() {
  return http.get('/knowledge_base/level/')
}

// лист сотрудников
export function getEmployeesList(params) {
  return http.get('/users/user/staffs/', { params })
}

// лист учителей
export function getTeachersList(params) {
  return http.get('/users/user/?', { params })
}

// лист учеников
export function getPupilsList(params) {
  return http.get('/users/user/students/', { params })
}

export function getUserActivity(params) {
  return http({
    method: 'GET',
    url: '/users/activity/',
    params,
  })
}

export function getDashboardStatistics(params) {
  return http({
    method: 'GET',
    url: '/users/dashboard-statics/',
    params,
  })
}

export function getUsersForChat(params) {
  return http({
    url: '/users/user/get_user_for_room/',
    method: 'get',
    params,
  })
}

export function importUsersFromFile(data) {
  return http({
    url: '/users/user/import_users_from_excel/',
    method: 'POST',
    data,
  })
}

export function getTeachersAttachedSupervisor({ id, ...rest }) {
  return http({
    url: `/users/user/${id}/get_related_teachers/`,
    method: 'GET',
    params: rest,
  })
}

export function getTeachersNotAttachedSupervisor({ id, ...rest }) {
  return http({
    url: `/users/user/${id}/get_not_related_teachers/`,
    method: 'GET',
    params: rest,
  })
}

export function updateTeachersSupervisor({ id, ...rest }) {
  return http({
    url: `/users/user/${id}/add_teachers/`,
    method: 'PATCH',
    data: rest,
  })
}

export function deleteTeachersSupervisor({ id, ...rest }) {
  return http({
    url: `/users/user/${id}/delete_teacher/`,
    method: 'DELETE',
    data: rest,
  })
}

export function exportUsersExcel(params) {
  return http({
    url: '/users/user/export_users_from_excel/',
    method: 'GET',
    params,
    responseType: 'blob',
  })
}
