export default {
  approvedBranch: {
    results: [],
  },
  rejectedBranch: [],
  modifiedBranch: {
    results: [],
  },
  branchesGroup: {
    results: [],
  },
  oneBranchGroup: {
    branches_info: [],
  },
  BranchForGroup: {
    results: [],
  },
}
