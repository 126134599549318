export default {
  transactions: {
    results: [],
  },
  clientsList: {
    results: [],
  },
  clientsList2: {
    results: [],
  },
  incomeStatistics: {},
  userTransactions: {
    results: [],
  },
  userTransactionsHistory: {
    results: [],
  },
}
