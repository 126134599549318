export default {
  GET_TRANSACTIONS: state => state.transactions,
  GET_CLIENTS_LIST: state => state.clientsList,
  GET_CLIENTS_LIST_FOR_FILTER: state => state.clientsList2,
  GET_INCOME_STATISTICS_LIST: state => state.incomeStatistics,
  GET_USER_TRANSACTION_HISTORY: state => state.userTransactionsHistory,
  GET_USER_TRANSACTION(state) {
    return state.userTransactions.results.map(data => {
      if (data.type === 'income') {
        return {
          // mode: 'finance.income',
          avatarVariant: 'light-success',
          avatar: 'PlusIcon',
          type: data.type,
          types: new Date(data.paid_at).toLocaleString().split(',')[0],
          textVariant: 'text-success',
          amount: `+${data.amount}`,
        }
      }
      return {
        // mode: 'finance.outcome',
        avatarVariant: 'light-danger',
        avatar: 'MinusIcon',
        type: data.type,
        types: new Date(data.paid_at).toLocaleString().split(',')[0],
        textVariant: 'text-danger',
        amount: `-${data.amount}`,
      }
    })
  },
}
