export default [
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/modules/Chat/index.vue'),
    meta: {
      pageTitle: '',
      breadcrumb: [
        {
          text: 'Chat',
          active: false,
        },
      ],
      action: 'read',
      resource: ['all'],
    },
  },
]
